import { Fragment } from 'react';
import dynamic from 'next/dynamic'; 
// import Pricing from 'components/reuseable/pricing'
import type { NextPage } from 'next';
import { fadeInAnimate, slideInDownAnimate } from 'utils/animation';
import NextLink from 'components/reuseable/links/NextLink';
// -------- custom component -------- //
import { Process1 } from 'components/blocks/process';
import { processList1 } from 'data/process';
import { Director } from 'components/blocks/testimonial';
import { Services22 } from 'components/blocks/services';

import { Portfolio2 } from 'components/blocks/portfolio';
import { FAQ2 } from 'components/blocks/faq';
import { accordionList2 } from 'data/faq';
import ContactFooterCombo from 'components/reuseable/ContactFooterCombo';

import SEO from 'components/common/SEO';
import * as All from 'data/keywords';
import useLogVisitor from 'hooks/useLogVisitor.js';

const VideoDI = dynamic(() => import('components/blocks/video/Video'));
const LocalDI = dynamic(() => import('components/blocks/seo/Local'), { ssr: false });
const InternationalDI = dynamic(() => import('components/blocks/seo/International'), { ssr: false });

const details = {
  pageName: 'Molto SEO Agency London',
  categories: 'seo, web design',
  description:
    'Transparent, affordable and effective technical SEO services in London for professionals, agencies and enterprise.',
  parentDirectories: ''
};

const Home: NextPage = () => {
  useLogVisitor();

  return (
    <>
      {/* <SEOAnalysis /> */}
      {/* ========== SEO META values ========== */}
      <SEO
        title={'Molto SEO London'}
        description={details.description}
        tag={details.categories}
        urlPT={``}
        urlENPT={``}
        url={`${All.HomeList[0].title.toLowerCase().replace(/ /g, '-')}`}
        image={`img/${All.HomeList[0].title.toLowerCase().replace(/ /g, '-')}.jpg`}
      // updated=""
      // published=""
      // modified=""
      />
      <Fragment>
        <main className="content-wrapper home">
          {/* ========== hero section ========== */}
          <div style={fadeInAnimate('900ms')}>
            <VideoDI
              heading={"AI-Driven SEO"}
              title1="AI"
              title2="SEO"
              title3=""
              movie="1.mp4"
              placeholder="1-bg.jpg"
              button="website-test"
              fontsize1={150}
              fontsize2={150}
              body=""
              intro=" helps companies significantly improve their Google ranking. If you want to accelerate
              your business online, we are experts using AI and proprietary automation tools to boost sales."
            />
            {/* **OPTION */}
            {/* Unlock the full potential of your website with our proven SEO strategies designed to boost traffic, improve rankings, and drive sales. */}
          </div>

          <div id="ai-seo"></div>

          {/* ========== AI SEO section ========== */}
          <Process1
            title={``}
            subtitle={`AI For Faster Growth`}
            body={[
              <p className="lead" key="100">
                {`Dominate your industry online with our AI-powered SEO automation: Unlock quick wins, boost your revenue, and secure your spot 
                at the top of Google effortlessly.`}
              </p>,
              <p
                className="lead"
                key="101"
              >{`Our in-house tools download the details of every website in your industry, analyse all their keywords, Google positions and backlinks. 
                Then we compare this data with your site. We make a list of all the "quick wins" we can add to your site to get short term success. 
                 The resulting increase in turnover will demonstrate the benefit of our service and justify sticking with as we establish you at the top of Google for the keywords of your industry.`}</p>,
              <p className="lead text-capitalize" key="102">
                <NextLink
                  className="more hover "
                  href={`/blog/${All.AI[0].title.toLowerCase().replace(/ /g, '-')}`}
                  title={All.AI[0].title}
                />{' '}
              </p>
            ]}
            list={processList1}
            buttonLink="/#contact"
            buttonTitle="Get in touch"
            displayButton={'d-inline'}
            src={`/img/${All.AI[10].title.toLowerCase().replace(/ /g, '-')}-2.png`}
            alt={All.AI[0].title}
          />

          {/* ========== LOCAL SEO section ========== */}
          <div id="localSEO" className="mt15"></div>
          <LocalDI
            heading={All.localSEOPackages[1].title}
            initialslide={0}
            body="Our local & Technical SEO, Social & PPC services target your exact demographic in the UK."
          />
          {/* ========== INTERNATIONAL SEO section ========== */}
          <InternationalDI
            heading={`${All.HomeList[9].title}s`}
            body={`Our ${All.HomeList[9].title}s have developed SEO straegies and multilingual software for the biggest tech companies in
                    the USA. We apply the same strategies to make your site visible in searches for European keywords.`}
          />
          {/* ========== DIRECTOR section ========== */}
          {/* map */}
          <Director title={All.HomeList[6].title} />

          {/* ========== DEVELOPMENT projects carousel section ========== */}
          <Portfolio2 title={`${All.HomeList[7].title}s`} />

          {/* ========== 10 POINT PROCESS section ========== */}
          {/* map */}
          <FAQ2
            title1="10 POINT"
            title2="PROCESS"
            movie="technical-seo-agency.webm"
            // movie="https://www.shutterstock.com/shutterstock/videos/1052689403/preview/stock-footage-digital-city-seamless-loop-abstract-d-hologram-render-with-futuristic-matrix-flying-through.webm"
            placeholder="1-bg.jpg"
            body=""
            // body={`Molto SEO has developed a 10 Point PROCESS to stay focused only on the on-page, off-page and ${All.HomeList[8].title} that really affect your search results.
            // The bottom line is AI can help you create better optimised content that your readers will love and engage with.`}
            subtitle={All.HomeList[8].title}
            accordion={accordionList2}
            titleColor="muted"
            fontsize1={110}
            fontsize2={100}
          />
          {/* <Pricing /> */}

          {/* ========== Website SEO Audit Services ========== */}
          <Services22 title="" wordCloud={All} />
        </main>

        <ContactFooterCombo location="london" />
      </Fragment>
    </>
  );
};

export default Home;
